import request from '@/utils/request'
import { SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER } from '@/utils/token'

// 充值
export function rechargeBalance(data) {
  return request({
    url: `/balance/recharge`,
    method: 'post',
    data
  })
}

// 查询交易信息
export function findPayTrade(id) {
  return request({
    url: `/pay_trades/${id}`,
    method: 'get'
  })
}

// 查询交易信息(轮询调用, 不自动延长身份过期时间)
export function findPayTradeSkipExtendAuth(id) {
  const headers = {}
  headers[SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER] = '1'

  return request({
    url: `/pay_trades/${id}`,
    method: 'get',
    headers: headers
  })
}
