<template>
  <div v-if="isSimiotContractingParty || isMerchant">
    <a-row type="flex" class="iotplt-margin-bottom-15px">
      <a-col flex="70px">
        <a-icon
          type="transaction"
          :style="{fontSize: '55px', color: 'rgb(95, 166, 248)' }"
        />
      </a-col>

      <a-col flex="200px">
        <a-spin :spinning="loadingBalance">
          <a-statistic
            class="iotplt-statistic"
            title="账户余额"
            prefix="￥"
            :precision="2"
            :value="balance"
          />
        </a-spin>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col>
        <a-form
          class="iotplt-compact-form"
          :form="form"
          layout="vertical"
          @submit="handleSubmit"
        >
          <a-form-item label="充值金额">
            <a-input-number
              style="width: 100%;"
              :min="0.01"
              :max="10000000"
              :step="0.01"
              :precision="2"
              v-decorator="['amount', {
                rules: [
                  { required: true, message: '请输入充值金额' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="充值说明">
            <a-textarea
              v-decorator="['description', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>

          <a-form-item label="支付平台">
            <a-radio-group
              v-decorator="['pay_way', {
                rules: [
                  { required: true, message: '请选择支付平台' }
                ]
              }]"
            >
              <a-radio-button
                class="pay-way-btn wechat-pay-btn"
                value="wechat_pay"
              />

              <a-radio-button
                class="pay-way-btn alipay-btn"
                value="alipay"
                style="display:none;"
              />
            </a-radio-group>

            <a-row class="bank" style="display:none;">
              <a-col :span="3" class="left">
                <div>对公</div>
                <div>打款</div>
              </a-col>
              <a-col :span="20" class="right">
                <div>户名：有限公司</div>
                <div>开户行：</div>
                <div>账号：</div>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item>
            <a-button
              :loading="submitting"
              type="primary"
              html-type="submit"
            >
              去付款
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    <span style="color:red;">没有该页面的权限</span>
  </div>
</template>

<script>
import { findAgentFinanceOverview, findAgentIsSimiotContractingParty } from '@/api/agent'
import { rechargeBalance } from '@/api/pay_trade'

export default {
  name: 'RechargeBalance',
  data() {
    return {
      balance: undefined,
      loadingBalance: true,
      submitting: false,
      form: this.$form.createForm(this, { name: 'balance_recharge' }),
      isSimiotContractingParty: false // 主体是否有限公司
    }
  },
  computed: {
    isMerchant() {
      return this.$store.getters.userRole === 'merchant'
    }
  },
  created() {
    this.fetchBalanceStatistic()
    this.fetchSimiotContractingPartyData()
  },
  methods: {
    // 查询主体是否有限公司
    fetchSimiotContractingPartyData() {
      this.loadingSimiotContractingParty = true
      findAgentIsSimiotContractingParty(this.$store.getters.userAgentId).then((res) => {
        if (res.code === 0) {
          this.isSimiotContractingParty = res.data.is_simiot_contracting_party
        }
        this.loadingSimiotContractingParty = false
      })
    },

    fetchBalanceStatistic() {
      this.loadingBalance = true
      findAgentFinanceOverview(this.$store.getters.userAgentId).then((res) => {
        if (res.code === 0) {
          this.balance = res.data.balance
        }
        this.loadingBalance = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          rechargeBalance(values).then((res) => {
            if (res.code === 0) {
              this.$router.push({ name: 'pay_trade_info', params: { id: res.data.pay_trade_id }})
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pay-way-btn {
  height: 65px;
  width: 200px;
  margin-right: 20px;
  border-radius: 0;
}
.wechat-pay-btn {
  background: url("~@/assets/images/wechat_pay_logo.svg") no-repeat center;
}

.alipay-btn {
  background: url("~@/assets/images/alipay_logo.svg") no-repeat center;
}

.bank {
  float: right;
  width: 400px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  padding-left: 6px;
  height: 65px;

  .left {
    border-right: 2px solid #e4dbdb;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
  }

  .right {
    margin-left:13px;
  }
}
</style>
